import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Poi from './Poi';
import { LatLngTuple } from 'leaflet';

const prahaLatLon: LatLngTuple = [50.0877, 14.4204];
const zoom: number = 14;

interface MapProps {
  poiData: Poi[];
}

const Map: React.FC<MapProps> = ({ poiData }) => {
  return (
    <MapContainer id="map" center={prahaLatLon} zoom={zoom}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {poiData.map((poi) => (
        <Marker key={poi.id} position={[poi.lat, poi.lon]}>
          <Popup>
            <strong>{poi.name}</strong><br />
            {poi.description}<br />
            {poi.address}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Map;
