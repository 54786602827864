import React, { useEffect, useState } from 'react';
import Map from './Map';
import Poi from './Poi';

function App() {
  const [poiData, setPoiData] = useState<Poi[]>([]);

  useEffect(() => {
    fetch('/poi.json')
    .then((response) => response.json())
    .then((data) => setPoiData(data));
  }, []);

  return (
    <div className="App">
      <Map poiData={poiData} />
    </div>
  );
}

export default App;
